import cx from 'classnames'
import styles from './athena-search-button.module.scss'
import { FC, useCallback, useContext } from 'react'
import dynamic from 'next/dynamic'
import { CloseIcon, SearchIcon } from '@grille/components/icons'
import { Color } from '@grille/constants/colors'
import { PageContext, useUpdatePageCtx } from '../../../utils/contexts/pageContext'
import { Panels } from '../types'

const AthenaSearchModal = dynamic(() => import('./athena-search-modal'), { ssr: false })

const AthenaSearchButton: FC<AthenaSearchButtonProps> = ({}) => {
  const { pageStore } = useContext(PageContext)
  const { updateStore } = useUpdatePageCtx()
  const showSearch: boolean = pageStore?.openedPanel === Panels.searchPanel

  //stablize onClose function so it does not recreate
  const onClose = useCallback(() => {
    updateStore('openedPanel', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={cx(styles['d-athena-search-button'])}>
      <button
        onClick={() => updateStore('openedPanel', showSearch ? null : Panels.searchPanel)}
        className={cx(styles['d-athena-search-button__button'])}
        aria-label='toggle athen-search model'
      >
        <div
          className={cx(styles['d-athena-search-button__button__label'], {
            [styles['d-athena-search-button__button__label--modal-closed']]: !showSearch
          })}
        >
          {showSearch ? (
            <>
              <span>Close search</span>
              <CloseIcon
                className={cx(styles['d-athena-search-button__icon'])}
                width='24'
                height='24'
                color={Color.white}
              />
            </>
          ) : (
            <>
              <div className={cx(styles['d-athena-search-button__text-group'])}>
                {[
                  'Search Drive',
                  'Search cars for sale',
                  'Search reviews',
                  'Search news',
                  'Search new cars',
                  'Search advice'
                ].map((text, index) => (
                  <span
                    key={`${text}-${index}`}
                    className={cx(styles['d-athena-search-button__text-group__text'])}
                  >
                    {text}
                  </span>
                ))}
              </div>

              <SearchIcon
                className={cx(styles['d-athena-search-button__icon'])}
                width='24'
                height='24'
                color={Color.white}
              />
            </>
          )}
        </div>
      </button>
      <AthenaSearchModal show={showSearch} onClose={onClose} />
    </div>
  )
}

export default AthenaSearchButton

type AthenaSearchButtonProps = {}
