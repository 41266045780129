'use client'
import cx from 'classnames'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { PageContext, useUpdatePageCtx } from '../../utils/contexts/pageContext'
import HeaderMenu from './headerMenu'
import styles from './navbar.module.scss'
import { Panel, Panels } from './types'
import SideMenuContainer from './sideMenuContainer'
import { LARGE_WINDOW_WIDTH } from '../../constants'
import { PubSubStatic } from '../../utils/class/PubSub'
import Icon from '../common/icon'
import { Color } from '@grille/constants/colors'
import Link from '../common/link'
import Logo from '../common/logo'
import NavbarItems from './navbar-items'
import { isTestEnv } from '@grille/utils/functions/isTestEnv'
import isEmpty from 'lodash/isEmpty'
import { getRuntimeEnv } from '@grille/utils/functions/get-runtime-env'
import AthenaSearchButton from './athena-search-button'

const isTest = isTestEnv()
// TODO: remove this when we have implemented feature flags

const NavBarWithProps: FunctionComponent<NavBarWithProps> = ({
  mobilePersistStickyHeader = false,
  headerMenus,
  hamburgerMenus
}) => {
  const { pageStore } = useContext<PageContextProps>(PageContext)
  const { updateStore } = useUpdatePageCtx()
  const pagePubSub: PubSubStatic | null = pageStore?.pagePubSub ?? null
  const [isDesktop, setIsDesktop] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > LARGE_WINDOW_WIDTH) {
        setIsDesktop(true)
      }

      if (window.innerWidth < 768) {
        setIsMobile(true)
      }
    }
  }, [])

  /* bail early */
  if (isEmpty(headerMenus && hamburgerMenus)) {
    return null
  }

  const isNonProd = (getRuntimeEnv('NEXT_PUBLIC_DEPLOY_ENV') as string) !== 'prod'
  const isDriveAIpage = pageStore.pageInfo?.asPath?.includes('drive-ai')
  const navMenus = headerMenus?.edges ?? []
  const sideMenuData = hamburgerMenus?.edges ?? []
  const sideMenuCheckboxId: string = 'side-menu-checkbox'
  const showSideMenu: boolean = pageStore?.openedPanel === Panels.sideMenu
  const showSearch: boolean = pageStore?.openedPanel === Panels.searchPanel

  const adType: string | false = pageStore?.gam_oop_pos0?.img?.['data-adtype'] ?? false

  const HamburgerButton = ({ showSideMenu }: { showSideMenu: boolean }) => {
    return (
      <button
        onClick={() => {
          if (showSideMenu) {
            updateStore('openedPanel', null)
          } else {
            updateStore('openedPanel', Panels.sideMenu)
          }
        }}
        type='button'
        className={cx(styles['drive-nav-wrapper__hamburger-btn'])}
        aria-label='toggle menu'
      >
        {/* when js is enabled, the check status is not preserved after the updatePasetore.
          Hovever, when js is diabled, check status fully managed by label.*/}
        <label htmlFor={sideMenuCheckboxId}>
          <Icon
            name={showSideMenu ? 'CloseIcon' : 'HamburgerMenuIconStraight'}
            width='3rem'
            height='3rem'
            color={Color.white}
            className={cx(styles['drive-nav-wrapper__icon-btn'])}
          />
        </label>
      </button>
    )
  }

  const isPanelOpened: boolean = showSideMenu || showSearch
  return (
    <>
      <header
        role='banner'
        className={cx(
          { [styles['drive-nav-wrapper__sticky-gam-menu']]: adType === 'sticky' || isDriveAIpage },
          styles['drive-nav-wrapper__header-menu']
        )}
      >
        <HeaderMenu
          isPanelOpened={isPanelOpened}
          mobilePersistStickyHeader={isDriveAIpage && isNonProd ? true : mobilePersistStickyHeader}
          // Only publish when sticky menus are used, so on mobile or tablet
          pagePubSub={isDesktop ? null : pagePubSub}
        >
          {/* classname main-nav is used as selector in other logics in the code*/}
          <nav className={cx(styles['drive-nav-wrapper__main-nav'], 'main-nav')} role='navigation'>
            <div
              id='navbarHeaderSection'
              className={cx(styles['drive-nav-wrapper__hamburger-btn-wrapper'])}
            >
              <HamburgerButton showSideMenu={showSideMenu} />
            </div>

            <div className={cx(styles['drive-nav-wrapper__link-wrapper'])}>
              <Link
                href='/'
                isExternal={undefined}
                isJumpLinkOrHashFragment={undefined}
                navigateToRoute={undefined}
              >
                <a>
                  <Logo
                    id='top-nav-logo'
                    className={cx(styles['drive-nav-wrapper__link-wrapper__logo'])}
                    data-testid={isTest ? 'drive-logo' : undefined}
                  />
                </a>
              </Link>
            </div>

            <div className={cx(styles['drive-nav-wrapper__items-wrapper'])}>
              <NavbarItems items={navMenus} />
            </div>
            <div className={cx([styles['drive-nav-wrapper__athena-button-wrapper']])}>
              <AthenaSearchButton />
            </div>
          </nav>
        </HeaderMenu>
      </header>
      {/* freeze scroll when panel is opened under 768 screen size */}
      {typeof window !== 'undefined' && isMobile && showSideMenu && (
        <style
          dangerouslySetInnerHTML={{
            __html: ` html, body { max-height: 100%; overflow:hidden; }`
          }}
        />
      )}
      <SideMenuContainer
        showSideMenu={showSideMenu}
        sideMenuData={sideMenuData}
        checkboxTrackId={sideMenuCheckboxId}
      />
    </>
  )
}

type NavBarWithProps = {
  mobilePersistStickyHeader?: boolean
  headerMenus: TMenuResponse | null
  hamburgerMenus: TMenuResponse | null
}

type PageContextProps = { pageStore: PageStore; updateStore: Function }

type PageStore = {
  openedPanel: Panel | null
  pagePubSub: PubSubStatic | null
  pageInfo: Record<string, any>
  gam_oop_pos0: {
    img?: {
      [key: string]: any
    }
  }
}

export type TMenuResponse = {
  [key: string]: Edge[]
}

export type Edge = {
  [key: string]: any
}

export default NavBarWithProps
