import { Icon } from '../common/icon'
import { NamedIconProps } from '../common/icon/types'

export const Svg9NowLogoWhite = (props?: NamedIconProps) => {
  return <Icon {...props} name='Svg9NowLogoWhite' />
}
export const AncapSafetyIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='AncapSafetyIcon' />
}
export const AddIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='AddIcon' />
}
export const AddInCircleIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='AddInCircleIcon' />
}
export const CircleAddIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CircleAddIcon' />
}
export const AdventureIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='AdventureIcon' />
}
export const AgeLogoStackedWhite = (props?: NamedIconProps) => {
  return <Icon {...props} name='AgeLogoStackedWhite' />
}
export const ArrowBackIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ArrowBackIcon' />
}
export const ArrowDownwardsIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ArrowDownwardsIcon' />
}
export const ArrowForwardIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ArrowForwardIcon' />
}
export const ArrowUpwardsIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ArrowUpwardsIcon' />
}
export const Awards = (props?: NamedIconProps) => {
  return <Icon {...props} name='Awards' />
}
export const BackToTopIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BackToTopIcon' />
}
export const BodyTypeHatchIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeHatchIcon' />
}
export const BodyTypeConvertibleIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeConvertibleIcon' />
}
export const BodyTypeSuvIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeSuvIcon' />
}
export const BodyTypeSedanIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeSedanIcon' />
}
export const BodyTypeUteIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeUteIcon' />
}
export const BodyTypePeopleMoverIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypePeopleMoverIcon' />
}
export const BodyTypeVanIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeVanIcon' />
}
export const BodyTypeWagonIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeWagonIcon' />
}
export const BodyTypeCabChassisIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeCabChassisIcon' />
}
export const BodyTypeCoupeIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BodyTypeCoupeIcon' />
}
export const BtLogoNotaglineWhite = (props?: NamedIconProps) => {
  return <Icon {...props} name='BtLogoNotaglineWhite' />
}
export const CancelFillIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CancelFillIcon' />
}
export const CarGenericIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CarGenericIcon' />
}
export const CarIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CarIcon' />
}
export const CaretTopRightIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CaretTopRightIcon' />
}
export const ChevronDown = (props?: NamedIconProps) => {
  return <Icon {...props} name='ChevronDown' />
}
export const ChevronLeftIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ChevronLeftIcon' />
}
export const ChevronRightIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ChevronRightIcon' />
}
export const ChevronUp = (props?: NamedIconProps) => {
  return <Icon {...props} name='ChevronUp' />
}
export const CityIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CityIcon' />
}
export const ClearIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ClearIcon' />
}
export const CloseIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CloseIcon' />
}
export const CloseOutline = (props?: NamedIconProps) => {
  return <Icon {...props} name='CloseOutline' />
}
export const CommentIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CommentIcon' />
}
export const DcotyCategoryWinnerIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='DcotyCategoryWinnerIcon' />
}
export const DcotyFinalistIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='DcotyFinalistIcon' />
}
export const DcotyWinnerIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='DcotyWinnerIcon' />
}
export const DealsDollarSignIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='DealsDollarSignIcon' />
}
export const DiscIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='DiscIcon' />
}
export const DownloadIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='DownloadIcon' />
}
export const DrivetrainIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='DrivetrainIcon' />
}
export const ElectricCarIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ElectricCarIcon' />
}
export const ElectricEngineIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ElectricEngineIcon' />
}
export const ElectricVehicleStationIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ElectricVehicleStationIcon' />
}
export const EmailIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='EmailIcon' />
}
export const EcoIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='EnergyBoltIcon' />
}
export const EngineIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='EngineIcon' />
}
export const EnginePowerIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='EnginePowerIcon' />
}
export const ExpandLessIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ExpandLessIcon' />
}
export const ExpandCircleLeftIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ExpandCircleLeftIcon' />
}
export const ExpandCircleRightIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ExpandCircleRightIcon' />
}
export const ExpandMoreIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ExpandMoreIcon' />
}
export const FacebookIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FacebookIcon' />
}
export const FamilyIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FamilyIcon' />
}
export const FilterClosedIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FilterClosedIcon' />
}
export const FilterOpenIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FilterOpenIcon' />
}
export const FilterIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FilterIcon' />
}
export const FindDealerIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FindDealerIcon' />
}
export const FirstCarIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FirstCarIcon' />
}
export const FuelIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FuelIcon' />
}
export const FullscreenExitIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FullScreenExitIcon' />
}
export const FullscreenIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FullScreenIcon' />
}
export const GreenTick = (props?: NamedIconProps) => {
  return <Icon {...props} name='GreenTick' />
}
export const HamburgerMenuIconSlanted = (props?: NamedIconProps) => {
  return <Icon {...props} name='HamburgerMenuIconSlanted' />
}
export const HistoryIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='HistoryIcon' />
}
export const HomeIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='HomeIcon' />
}
export const HybridEngineIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FuelTypeHybridIcon' />
}
export const FilledInfoIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FilledInfoIcon' />
}
export const InfoIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='InfoIcon' />
}
export const Insights = (props?: NamedIconProps) => {
  return <Icon {...props} name='Insights' />
}
export const InstagramIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='InstagramIcon' />
}
export const LinkIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='LinkIcon' />
}
export const LinkedinIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='LinkedinIcon' />
}
export const LiveChat = (props?: NamedIconProps) => {
  return <Icon {...props} name='LiveChat' />
}
export const LocationIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='LocationIcon' />
}
export const LocationFilledIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='LocationFilledIcon' />
}
export const LockIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='LockIcon' />
}
export const MenuIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='MenuIcon' />
}
export const MinusIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='MinusIcon' />
}
export const CircleMinusIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CircleMinusIcon' />
}
export const NinecomauLogoWhite = (props?: NamedIconProps) => {
  return <Icon {...props} name='NinecomauLogoWhite' />
}
export const OpenNewTabArrowIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='OpenNewTabArrowIcon' />
}
export const Performance = (props?: NamedIconProps) => {
  return <Icon {...props} name='Performance' />
}
export const PetrolStationIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='PetrolStationIcon' />
}
export const PhoneIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='PhoneIcon' />
}
export const PhotoIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='PhotoIcon' />
}
export const PlayIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='PlayIcon' />
}
export const PlayIconRounded = (props?: NamedIconProps) => {
  return <Icon {...props} name='PlayIconRounded' />
}
export const PrestigeIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='PrestigeIcon' />
}
export const Profile = (props?: NamedIconProps) => {
  return <Icon {...props} name='Profile' />
}
export const SearchIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='SearchIcon' />
}
export const SearchRound = (props?: NamedIconProps) => {
  return <Icon {...props} name='SearchRound' />
}
export const ShareIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ShareIcon' />
}
export const SmhLogoWhite = (props?: NamedIconProps) => {
  return <Icon {...props} name='SmhLogoWhite' />
}
export const SortIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='SortIcon' />
}
export const SportIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='SportIcon' />
}
export const TagIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='TagIcon' />
}
export const Terrain = (props?: NamedIconProps) => {
  return <Icon {...props} name='Terrain' />
}
export const ThumbnailsIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ThumbnailsIcon' />
}
export const TopFiveIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='TopFiveIcon' />
}
export const TransmissionIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='TransmissionIcon' />
}
export const Trophy = (props?: NamedIconProps) => {
  return <Icon {...props} name='Trophy' />
}
export const TwitterIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='TwitterIcon' />
}
export const Union = (props?: NamedIconProps) => {
  return <Icon {...props} name='Union' />
}
export const ViewDay = (props?: NamedIconProps) => {
  return <Icon {...props} name='ViewDay' />
}
export const WarrantyIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='WarrantyIcon' />
}
export const WatodayLogoNotaglineWhite = (props?: NamedIconProps) => {
  return <Icon {...props} name='WatodayLogoNotaglineWhite' />
}
export const WebsiteIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='WebsiteIcon' />
}
export const WorkIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='WorkIcon' />
}
export const YoutubeIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='YoutubeIcon' />
}
export const Pin = (props?: NamedIconProps) => {
  return <Icon {...props} name='PinIcon' />
}
export const QuestionMarkIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='QuestionMarkIcon' />
}
export const VideoCameraIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='VideoCameraIcon' />
}
export const HamburgerMenuIconStraight = (props?: NamedIconProps) => {
  return <Icon {...props} name='HamburgerMenuIconStraight' />
}
export const SpotifyIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='SpotifyIcon' />
}
export const BackIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='BackIcon' />
}
export const CancelIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CancelIcon' />
}
export const MuteIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='MuteIcon' />
}
export const VolumeIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='VolumeIcon' />
}
export const OptionsIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='OptionsIcon' />
}
export const MinimizeIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='MinimizeIcon' />
}
export const SendIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='SendIcon' />
}
export const EmojiIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='EmojiIcon' />
}
export const AttachmentIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='AttachmentIcon' />
}
export const ZoomInIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ZoomInIcon' />
}
export const ZoomOutIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ZoomOutIcon' />
}
export const ImageIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ImageIcon' />
}
export const PinchZoomOutIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='PinchZoomOutIcon' />
}
export const MitsubishiLogoIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='MitsubishiLogoIcon' />
}
export const OdometerIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='OdometerIcon' />
}
export const KilometersIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='KilometersIcon' />
}
export const FuelTypePetrolIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FuelTypePetrolIcon' />
}
export const FuelTypeHybridIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FuelTypeHybridIcon' />
}
export const FuelTypeElectricIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FuelTypeElectricIcon' />
}
export const EngineSpecsIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='EngineSpecsIcon' />
}
export const EngineElectricIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='EngineElectricIcon' />
}
export const TransmissionSpecsIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='TransmissionSpecsIcon' />
}
export const FuelEffeciencyIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FuelEffeciencyIcon' />
}
export const DriveTypeIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='DriveTypeIcon' />
}
export const FactoryWarrantyIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FactoryWarrantyIcon' />
}
export const SeatsIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='SeatsIcon' />
}
export const CheckCircleIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CheckCircleIcon' />
}
export const LocationSpecsIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='LocationSpecsIcon' />
}
export const StrattonFinanceLogo = (props?: NamedIconProps) => {
  return <Icon {...props} name='StrattonFinanceLogo' />
}
export const CallDealerIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CallDealerIcon' />
}
export const CallIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='CallIcon' />
}
export const PriceDown = (props?: NamedIconProps) => {
  return <Icon {...props} name='PriceDown' />
}
export const DriveNused = (props?: NamedIconProps) => {
  return <Icon {...props} name='DriveNused' />
}
export const TrendingDown = (props?: NamedIconProps) => {
  return <Icon {...props} name='TrendingDown' />
}
export const ThumbsUpIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='ThumbsUpIcon' />
}
export const VerifiedWarrantyIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='VerifiedWarrantyIcon' />
}
export const FireIcon = (props?: NamedIconProps) => {
  return <Icon {...props} name='FireIcon' />
}
export const NusedSmall = (props?: NamedIconProps) => {
  return <Icon {...props} name='NusedSmall' />
}
export const NusedBig = (props?: NamedIconProps) => {
  return <Icon {...props} name='NusedBig' />
}
export const ShieldLock = (props?: NamedIconProps) => {
  return <Icon {...props} name='ShieldLock' />
}

export const Calender = (props?: NamedIconProps) => {
  return <Icon {...props} name='Calender' />
}
